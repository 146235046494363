<template>
  <v-container
    id="register"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <v-slide-y-transition appear>
          <v-card
            class="pa-3 pa-md-5 mx-auto"
            light
          >
            <pages-heading class="text-center display-3">
              {{ $t('app.torneos.name') }}
            </pages-heading>

            <v-row>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  light
                  rounded
                  outlined
                  dense
                  :placeholder="$t('app.site.select_a_branch')"
                  :items="sucursales"
                  item-text="nombre"
                  item-value="id"
                  v-model="filters.sucursal"
                  prepend-inner-icon="mdi-magnify"
                  @change="onSelectedBranch"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title class="text--black" v-text="data.item.nombre"></v-list-item-title>
                      <v-list-item-subtitle v-text="[data.item.localidad.nombre, data.item.municipio.nombre, data.item.estado.nombre].join(', ')"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                v-if="filters.sucursal"
              >
                <h2 class="display-2 text-center">
                  {{ $t('app.sucursales.single')}} {{ sucursales.filter(item => item.id === filters.sucursal)[0].nombre }}
                </h2>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-for="(item, index) in torneos"
                :key="`torneos-${index}`"
                v-if="torneos.length > 0"
              >
                <base-material-card
                  color="transparent"
                  image
                >
                  <template v-slot:image>
                    <v-img
                      :src="logo"
                      :lazy-src="preloader"
                      class="pointer"
                      @click="goToDetail(item.id)"
                    />
                  </template>
                  <v-card-title class="text-center font-weight-light">
                    {{ item.nombre }}
                  </v-card-title>

                  <v-card-text class="body-1 text-center mb-3 font-weight-light grey--text">

                  </v-card-text>

                  <template v-slot:actions>
                    <div class="display-2 font-weight-light grey--text text-center">
                      {{ item.status === 'programmed' ? $t('app.site.programmed') : ( item.status === 'active' ? $t('app.site.in_course') : $t('app.site.ended') ) }}
                    </div>
                  </template>
                </base-material-card>
              </v-col>
              <v-col
                v-if="torneos.length === 0"
              >
                <v-row>
                  <v-col
                    cols="4"
                    v-for="(icon, index) in placeholders"
                    :key="`icon-${index}`"
                  >
                    <v-img
                      class="center-element"
                      :class="icon.class"
                      :src="icon.icon"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PagesTorneos',

    components: {
      PagesBtn: () => import('./components/Btn'),
      PagesHeading: () => import('./components/Heading'),
    },

    data: () => ({
      sucursales: [],
      torneos: [],
      filters: {
        sucursal: null,
        torneo: null,
      },
      sections: [
        {
          icon: 'mdi-chart-timeline-variant',
          iconColor: 'primary',
          title: 'Marketing',
          text: 'We\'ve created the marketing campaign of the website. It was a very interesting collaboration.',
        },
        {
          icon: 'mdi-code-tags',
          iconColor: 'secondary',
          title: 'Fully Coded in HTML5',
          text: 'We\'ve developed the website with HTML5 and CSS3. The client has access to the code using GitHub.',
        },
        {
          icon: 'mdi-account-multiple',
          iconColor: 'cyan',
          title: 'Built Audience',
          text: 'There is also a Fully Customizable CMS Admin Dashboard for this product.',
        },
      ],
      socials: [
        {
          href: '#',
          icon: 'mdi-twitter',
          iconColor: '#1DA1F2',
        },
        {
          href: '#',
          icon: 'mdi-dribbble',
          iconColor: '#ea4c89',
        },
        {
          href: '#',
          icon: 'mdi-facebook',
          iconColor: '#3B5998',
        },

      ],
      placeholders: [
        {
          icon: require('../../assets/icons/soccer.svg'),
          class: 'mw30'
        },
        {
          icon: require('../../assets/icons/trophy.svg'),
          class: 'mw50'
        },
        {
          icon: require('../../assets/icons/strategy.svg'),
          class: 'mw30'
        }
      ]
    }),
    async mounted() {
      this.toggleLoader()
      this.sucursales = await this.getBranches()
      this.toggleLoader()
    },
    methods: {
      async onSelectedBranch () {
        this.toggleLoader()

        this.torneos = await this.getTorneosSearch({
          filters: {
            sucursal_id: this.filters.sucursal
          }
        })

        this.toggleLoader()
      },
      goToDetail(id) {
        this.$router.push({
          name: 'TournamentsDetail',
          params: {
            id: id
          }
        })
      }
    }
  }
</script>

<style lang="sass">
  #register
    .v-list-item__subtitle
      -webkic-line-clamp: initial
      -webkit-box-orient: initial

  .mw50
    max-width: 50% !important
</style>
